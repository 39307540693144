import * as React from "react"
// import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import styled from "@emotion/styled"

// import Button from "../buttons/button.js"

const HeroText = styled.div`
display: grid;

justify-content: center;
align-items: center;

margin-top: 32px;

.image {
	margin: 0 auto 16px auto;
}

.text {
	font-weight: bold;
	h1, p {
	text-align: center;
	color: white;
}

p {
	margin-top: 0;
}

h1 {
	font-weight: bold;
	font-size: 2.5rem;
	@media(min-width: 798px) {
		line-height: .5;
		font-size: 5rem;
	}
}

.col {
	margin-bottom: 50px;
}

.buttons {
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 20px;
}
}

@media(min-width: 768px) {
	h1 {margin-top: 0;}
}

`

const EmergLightSignageHero
	= () => (
		<div style={{
			display: "grid",
			marginBottom: "64px",
		}}>
			{/* You can use a GatsbyImage component if the image is dynamic */}
			<StaticImage
				style={{
					gridArea: "1/1",
					// You can set a maximum height for the image, if you wish.
					maxHeight: 590,
				}}
				layout="fullWidth"
				// You can optionally force an aspect ratio for the generated image
				aspectRatio={16 / 9}
				objectFit="center"
				// This is a presentational image, so the alt should be an empty string
				alt="emergency lighting signage installation"
				src={
					"./emergency-lights.jpg"
				}
				formats={["auto", "webp", "avif"]}
				loading="eager"
				placeholder="tracedSVG"
			/>

			{/* add an opacity with this row if needed */}
			{/* <div
			style={{
				// By using the same grid area for both, they are stacked on top of each other
				gridArea: "1/1",
				position: "relative",
				// This centers the other elements inside the hero component
				placeItems: "center",
				display: "grid",
				background: "rgba(0, 0, 0, 0.4)"
			}}
		></div> */}
			{/* add an opacity END */}

			<div
				style={{
					// By using the same grid area for both, they are stacked on top of each other
					gridArea: "1/1",
					position: "relative",
					// This centers the other elements inside the hero component
					placeItems: "center",
					display: "grid",
				}}
			>
				{/* Any content here will be centered in the component */}
				<HeroText>
					<div className="image">
						<StaticImage
							src="../../images/home/r_logo_icon.png"
							width={84}
							height={62}
							quality={95}
							formats={["AUTO", "WEBP", "AVIF"]}
							alt="small relentless logo"
							placeholder="tracedSVG"
						/>
					</div>
					<div className="text">
						<p>
							LICENSED &amp; INSURED
						</p>
						<h1>
							Emergency Lighting Signage
						</h1>
						{/* <div className="col buttons">
						<a href="tel:12504629111" title="call 250-462-9111">
							<Button cta="Call Now" />
						</a>
					</div> */}
					</div>
				</HeroText>
			</div>
		</div>
	)

export default EmergLightSignageHero

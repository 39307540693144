import * as React from "react"
import styled from "@emotion/styled"

const Wrapper = styled.div`
  max-width: var(--site-width);
  margin: 0 auto;
`

const ContentWrapper = ({ children }) => (
	<Wrapper>
		{children}
	</Wrapper>
)

export default ContentWrapper
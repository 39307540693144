import * as React from "react"
import { Link } from "gatsby"
// import { StaticImage } from "gatsby-plugin-image"
// import { css } from "@emotion/react"

import Layout from "../../components/layout"
import Seo from "../../components/seo"
import ContentWrapper from "../../components/wrappers/contentWrapper"
import ContentPart from "../../components/services/template/content"
import EmergLightSignageHero from "../../components/heros/emergencyLightingSignageHero"

const TitleText = `
	Emergency Lighting Signage
`
const EmergLightingSignagePage = () => (
	<Layout>
		<Seo title={TitleText} description="Experiencing an emergency in the dark is bewildering and frightening for building occupants trying to evacuate a building safely. Therefore, emergency lights and exit signs are critical components of your fire and safety protection system." />
		<EmergLightSignageHero />
		<ContentWrapper>
			<ContentPart>
				<p>Experiencing an emergency in the dark is bewildering and frightening for building occupants trying to evacuate a building safely. Therefore, emergency lights and exit signs are critical components of your fire and safety protection system.</p>
				<p>You can rely on us to inspect, install, or upgrade emergency light systems throughout the Okanagan.</p>
				<p>Local and provincial regulations mandate the need for monthly and annual inspections of emergency light systems.</p>
				<p>Monthly Inspections check:</p>
				<ul>
					<li>pilot lights are damage-free and operate</li>
					<li>batteries are charged, and corrosion is absent from batteries and their terminals</li>
				</ul>
				<p>We will gladly perform your monthly emergency light inspections, or we can train your staff and provide you with a complementary logbook.</p>
				<p>Technicians are required for annual inspections because the regulations require paperwork certified by those with professional credentials.</p>
				<p>When performing professional annual emergency light inspections, you can expect:</p>
				<ul>
					<li>proper transmission of electricity is verified</li>
					<li>light heads are correctly aligned</li>
					<li>half-hour battery drain test is complete</li>
					<li>full inspection of each component</li>
					<li>illumination standards are met</li>
					<li>full hazard inspection</li>
					<li>paperwork reviewed and certification sticker awarded</li>
					<li>emergency lighting system is fully powered.</li>
				</ul>
				<p>
					<Link to="/contact/" title="emergency lighting maintenance and installation services">Contact us today for emergency light inspections and inspection training.</Link>
				</p>
			</ContentPart>
		</ContentWrapper>
	</Layout>
)

export default EmergLightingSignagePage
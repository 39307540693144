import * as React from "react"
import { css } from "@emotion/react"

const ContentPart = ({ children }) => (
	<>
		<div>
			<div css={css` display: grid; margin: 0 auto 64px auto; max-width: var(--max-width); `}>{children}</div>
		</div>
	</>
)

export default ContentPart